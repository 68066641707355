/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.logo-M {
  width: 100vw;
  height: 60px;
  padding: 24px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.logo-M img {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.light-hover a:hover {
  background: #333d48 !important;
  color: #ffffff !important;
}

.nav-menu {
  padding: 24px 24px 24px 24px;
  background-color: #ffffff;
  width: 230px;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  transition: 50ms;
  background: #212a33;
  display: flex;
  flex-direction: column;
  opacity: 100%;
}

.nav-menu-M {
  padding: 16px 16px 16px 16px;
  background-color: #ffffff;
  width: 300px;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 50px;

  transition: 100ms;
  background: #212a33;
  display: flex;
  flex-direction: column;
  opacity: 100%;

  position: fixed;

  left: -300%;
  transition: 150ms;
}

.nav-menu-M.open {
  left: 0;
  transition: 450ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  text-wrap: nowrap;
  cursor: pointer;
}

.nav-text-M {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
}

.fade-out {
  opacity: 0%;
  transition: 150ms;
}

.nav-text a,
.nav-text div {
  text-decoration: none;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;

  margin-top: 6px;
  margin-bottom: 6px;
  text-wrap: nowrap;
  border-radius: 8px;
  align-self: stretch;
  text-align: start;
  line-height: 28px;
  display: flex;

  align-items: center;
  align-self: stretch;
  color: rgba(255, 255, 255, 1);
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px; /* 200% */
  text-wrap: nowrap;
}

.nav-text-M a,
.nav-text-M div {
  text-decoration: none;
  width: 100% !important;
  height: 34px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 6px;
  margin-bottom: 6px;

  border-radius: 8px;
  align-self: stretch;
  text-align: start;
  line-height: 28px;
  display: flex;
  text-overflow: clip;

  align-items: center;
  align-self: stretch;
  color: rgba(255, 255, 255, 1);
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px; /* 200% */
  text-wrap: nowrap;
}

.nav-text a:hover,
.nav-text div:hover,
.svg-image:hover {
  background-color: #f3f3f3;
  color: #333333;
}

.active .nav-button {
  background: #fa7532 !important;
  color: rgba(255, 255, 255, 1) !important;
  fill: white !important;
}

.nav-button:hover {
  fill: #333333;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nav-text span {
  margin-left: 8px;
}

.title {
  margin-top: 7px;
  margin-bottom: 7px;
  color: #ffffffa8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.title-M {
  margin-top: 12px;
  margin-bottom: 4px;
  color: #ffffffa8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.avoidBreakInside {
  break-inside: avoid;
}
